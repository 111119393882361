import React from 'react';
import styles from './Logout.module.scss';
import logout from 'assets/images/logoutScreen.png';
import { useTranslation } from 'react-i18next';

function Logout() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={`row ${styles.mainWrap}`}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-md-7 col-9 d-flex justify-content-center mb-2">
            <h1 className={styles.messageColor}>{t('LOGOUT.LOGGED_OUT')}</h1>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-md-7 col-9 d-flex justify-content-center">
            <img className={styles.imageWidth} src={logout} alt="logoutui" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center">
            <p className={`${styles.logoutText} ${styles.logout_description}`}>{t('LOGOUT.YOU_HAVE_BEEN_LOGGED_OUT')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center">
            <p className={`${styles.logoutText} ${styles.logout_description}`}>{t('LOGOUT.THANK_YOU')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Logout;
