import { toast as toaster, TypeOptions } from 'react-toastify';

export const toast = (message: string, type: TypeOptions) => {
  toaster(message, {
    type,
    hideProgressBar: true,
    isLoading: false,
    position: 'bottom-right',
    autoClose: 2500
  });
};

export const prevent = (event: any) => {
  if (event) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.stopImmediatePropagation) {
      event.stopImmediatePropagation();
    }
  }
  return false;
};

export const openLink = (link: string, target = '_blank') => {
  if (link) {
    const evLink = document.createElement('a');
    evLink.href = link;
    evLink.target = target;
    document.body.appendChild(evLink);
    evLink.click();
    if (evLink.parentNode) {
      evLink.parentNode.removeChild(evLink);
    }
  }
};
