import i18next from 'i18next';

const setUserState = (data: any) => {
  localStorage.setItem('user', JSON.stringify(data));
};

const getUserState = () => {
  const state = localStorage.getItem('user');
  if (state) {
    return JSON.parse(state);
  }
  return null;
};

const setUserSettingsState = (data: any) => {
  localStorage.setItem('user_settings', JSON.stringify(data));
  if (data && data.language) {
    i18next.changeLanguage(data.language);
  }
};

const getUserSettingsState = () => {
  const state = localStorage.getItem('user_settings');
  if (state && state !== 'undefined') {
    return JSON.parse(state);
  }
  return null;
};

const setPaymentsState = (data: any) => {
  localStorage.setItem('payments', JSON.stringify(data));
};

const getPaymentsState = () => {
  const state = localStorage.getItem('payments');
  if (state) {
    return JSON.parse(state);
  }
  return null;
};

export { setUserState, getUserState, setUserSettingsState, getUserSettingsState, getPaymentsState, setPaymentsState };
