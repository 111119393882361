import { firestore } from '../firebase';
import { collection, doc, query, where, getDoc, getDocs, updateDoc, deleteField, deleteDoc, setDoc, serverTimestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import { Message, UserQuota } from 'types';
import { DEFAULT_USER_QUOTA } from 'state_management/initialStates';
import { getUserState } from 'state_management/localStates';
import { API_URL } from '../constants';

export const createMessage = async (message: Message) => {
  try {
    const currentUser = getUserState();
    const newMessage: Message = {
      ...message,
      created_time: serverTimestamp(),
      updated_time: serverTimestamp(),
      created_by_user: currentUser?.user?.uid
    };
    return await setDoc(doc(collection(firestore, 'messages')), newMessage);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateMessageWithCondition = async () => {
  const q = query(collection(firestore, 'messages'), where('created_by_user', '==', 'sL9r4dRqOXOZNo9FQjuMX9Rle3o2'));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());

    updateDoc(doc.ref, {
      subject: 'Subject Wille',
      'to_user.f_name': 'Mr Shahbaz',
      updated: serverTimestamp()
    });
  });
};

export const updateMessageWithId = async (messageId: string, message: Message) => {
  try {
    const ref = doc(firestore, 'messages', messageId);
    return await updateDoc(ref, message);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addImageURlToList = async (messageId: string, imageURI: string) => {
  try {
    const ref = doc(firestore, 'messages', messageId);
    await updateDoc(ref, {
      attachment: arrayUnion(imageURI)
    });
  } catch (err) {
    console.error(err);
  }
};

export const deleteImageURLFromList = async (messageId: string, imageURI: string) => {
  try {
    const ref = doc(firestore, 'messages', messageId);
    await updateDoc(ref, {
      attachment: arrayRemove(imageURI)
    });
  } catch (err) {
    console.error(err);
  }
};

export const getMessagesForCurrentUser = async () => {
  try {
    const currentUser = getUserState();
    const q = query(collection(firestore, 'messages'), where('created_by_user', '==', currentUser?.user?.uid));
    const querySnapshot = await getDocs(q);
    const messages: Message[] = [];
    querySnapshot.forEach((doc) => {
      const message = { ...doc.data(), id: doc.id };
      messages.push(message as Message);
    });
    return messages;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getMessageWithId = async (messageId: string) => {
  try {
    const docRef = doc(firestore, 'messages', messageId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { ...docSnap.data(), id: docSnap.id } : null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const deleteMessageWithCondition = async () => {
  const q = query(collection(firestore, 'messages'), where('created_by_user', '==', 'sL9r4dRqOXOZNo9FQjuMX9Rle3o21'));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, ' => ', doc.data());
    deleteDoc(doc.ref);
  });
};

export const deleteMessageWithId = async (messageId: string) => {
  try {
    await deleteDoc(doc(firestore, 'messages', messageId));
  } catch (err) {
    console.error(err);
  }
};

export const deleteSingleFieldFromMessage = async (messageId: string) => {
  const userRef = doc(firestore, 'messages', messageId);

  // Remove the 'blocked' field from the document
  await updateDoc(userRef, {
    blocked: deleteField()
  });
};

export const getUserQuota = async () => {
  try {
    const currentUser = getUserState();
    const q = query(collection(firestore, 'user_quota'), where('_id', '==', currentUser?.user?.uid));
    const querySnapshot = await getDocs(q);
    const userQuotas: UserQuota[] = [];
    querySnapshot.forEach((doc) => {
      userQuotas.push(doc.data() as UserQuota);
    });
    return userQuotas && userQuotas[0];
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const addUserQuota = async (userUID: string) => {
  try {
    const newUserQuota = {
      ...DEFAULT_USER_QUOTA,
      _id: userUID
    };
    await setDoc(doc(collection(firestore, 'user_quota')), newUserQuota);
  } catch (err) {
    console.error(err);
  }
};

export const updateUserQuota = async (newUserQuota: UserQuota) => {
  try {
    const currentUser = getUserState();
    const q = query(collection(firestore, 'user_quota'), where('_id', '==', currentUser?.user?.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, newUserQuota);
    });
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const reschedule = async () => {
  try {
    const currentUser = getUserState();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_uid: currentUser?.user?.uid })
    };
    const response = await fetch(`${API_URL}/blockMessage`, requestOptions);
    return response && response.text();
  } catch (err) {
    console.log('[reschedule] Failed to reschedule, error: ', err);
    throw err;
  }
};
