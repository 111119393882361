import React, { useEffect } from 'react';
import styles from '../../Logout/Logout.module.scss';
import logout from 'assets/images/logoutScreen.png';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

function PaymentSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/');
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div className="container">
      <div className={`row ${styles.mainWrap}`}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-md-7 col-9 d-flex justify-content-center">
            <img className={styles.imageWidth} src={logout} alt="logoutui" />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center">
            <p className={`${styles.logoutText} ${styles.logout_description}`}>{t('PAYMENTS.YOUR_PAYMENT_WAS_SUCCESSFUL')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12 d-flex justify-content-center">
            <p className={`${styles.logoutText} ${styles.logout_description}`}>{t('PAYMENTS.THANK_YOU')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
