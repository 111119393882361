import React, { useEffect, useState } from 'react';
import styles from './BlockList.module.scss';
import innerLogo2 from 'assets/images/inner-logo2.png';
import blockIcon from 'assets/images/Block.png';
import { Message } from 'types';
import { getMessagesForCurrentUser, reschedule } from 'services/messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function BlockList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const getMessages = async () => {
      setLoading(true);
      const response = await getMessagesForCurrentUser();
      setMessages(response);
      setLoading(false);
    };
    getMessages();
  }, []);

  const rescheduleMessages = async () => {
    try {
      setRescheduling(true);
      await reschedule();
      setRescheduling(false);
    } catch (err) {
      console.log(err);
      setRescheduling(false);
    }
  };

  return (
    <div>
      <div className="inner-logo-2">
        <img src={innerLogo2} className="img-fluid" alt="logo" />
      </div>
      <div className="inner-container" hidden={loading}>
        <div className="container inner-box pt-0 pb-5">
          <h1 className="text-center page-heading">{t('BLOCK.HEADING')}</h1>
          {messages?.length ? (
            messages?.map((message) => {
              return (
                <div key={message?.id} className={`mb-3 ${styles.notifactionrow}`}>
                  <div className="d-flex">
                    <img src={blockIcon} className={`me-5 ${styles.usericon} `} alt="block-icon" />
                    <div className="align-self-start message-block">
                      <h4 className={styles.blockmessageheading}>{message?.subject}</h4>
                      <p>{message?.message}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className={`text-white text-center mt-4 ${styles.noMessage}`}>
              <h4 className="mt-4 mb-4">No Message found </h4>
            </div>
          )}
          <div className={`mb-5 ms-auto ${styles.resetBtn}`}>
            <button className={rescheduling ? 'rotating' : ''} onClick={rescheduleMessages}>
              <FontAwesomeIcon icon={faArrowRotateRight} />
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="text-center loadingSpinner text-light">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t('LOADING')}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default BlockList;
