const LANGUAGES = [
  {
    key: 'en',
    label: 'English'
  },
  {
    key: 'de',
    label: 'German'
  }
];

const API_URL = 'https://us-central1-owille-e02ab.cloudfunctions.net';

const MESSAGE_MAX_LENGTH = 160;

export { LANGUAGES, API_URL, MESSAGE_MAX_LENGTH };
