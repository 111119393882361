import { faPlusCircle, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from 'react';
import nameUser from 'assets/images/nameuser.png';
import subjectIcon from 'assets/images/subjecticon.png';
import saveIcon from 'assets/images/saveicon.png';
import calIcon from 'assets/images/calicon.png';
import crossIcon from 'assets/images/crossicon.png';
import popuserIcon from 'assets/images/popusericon.png';
import emailIcon from 'assets/images/emailicon.png';
import mobileIcon from 'assets/images/mobileicon.png';
import whatsappIcon from 'assets/images/whatsappicon.png';
import emailwhiteIcon from 'assets/images/emailiconwhite.png';
import mobilewhiteIcon from 'assets/images/mobileiconwhite.png';
import whatsappwhiteIcon from 'assets/images/whatsappiconwhite.png';
import popupsaveIcon from 'assets/images/popupsave.png';
import popupcrossIcon from 'assets/images/popupcross.png';
import { Modal, ProgressBar } from 'react-bootstrap';
import styles from './CreateMessage.module.scss';
import { EMPTY_MESSAGE, EMPTY_USER, EMPTY_USER_QUOTA } from 'state_management/initialStates';
import { Message, User, Attachment, UserQuota } from 'types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createMessage, getMessageWithId, getUserQuota, updateMessageWithId } from 'services/messages';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import { storage } from '../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router';
import { toast } from '../../helpers/utils';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { getUserState } from '../../state_management/localStates';
import { updateQuotaOnFileDeletion, updateQuotaOnFileUpload, updateUserQuotaOnMessageCreation, updateUserQuotaOnMessageUpdate } from 'services/userQuota';

function CreateMessage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { messageId } = useParams();
  const [loading, setLoading] = useState(false);
  const [addEmail, setAddEmail] = useState(false);
  const [addMobile, setAddMobile] = useState(false);
  const [user, setUser] = useState<User>(EMPTY_USER);
  const [addWhatsapp, setAddWhatsapp] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [blockTime, setBlockTime] = useState<Date | null>(null);
  const [message, setMessage] = useState<Message>(EMPTY_MESSAGE);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [reminderTime, setReminderTime] = useState<Date | null>(null);
  const [userQuota, setUserQuota] = useState<UserQuota>(EMPTY_USER_QUOTA);
  const [messageToEdit, setMessageToEdit] = useState<Message>(EMPTY_MESSAGE);

  useEffect(() => {
    console.log('USER QUOTA IS::: ==> ', userQuota);
  }, [userQuota]);

  const openUserModel = () => {
    setUser(message?.to_user);
    setShowUserModal(true);
  };

  const setFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setUser((prevState: User) => {
      return {
        ...prevState,
        f_name: event.target.value
      };
    });
  };

  const setLastName = (event: ChangeEvent<HTMLInputElement>) => {
    setUser((prevState: User) => {
      return {
        ...prevState,
        l_name: event.target.value
      };
    });
  };

  const setEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setUser((prevState: User) => {
      return {
        ...prevState,
        email: event.target.value
      };
    });
  };

  const setMobileNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setUser((prevState: User) => {
      return {
        ...prevState,
        mobile: event.target.value
      };
    });
  };

  const setWhatsappNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setUser((prevState: User) => {
      return {
        ...prevState,
        whatsapp: event.target.value
      };
    });
  };

  const setMessageSubject = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage((prevState: Message) => {
      return {
        ...prevState,
        subject: event.target.value
      };
    });
  };

  const setMessageText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage((prevState: Message) => {
      return {
        ...prevState,
        message: event.target.value
      };
    });
  };

  const saveUserDetails = () => {
    if (!validateUserData()) {
      return;
    }
    setMessage((prevState: Message) => {
      return {
        ...prevState,
        to_user: user
      };
    });
    setShowUserModal(false);
  };

  const updateBlockTime = (date: Date) => {
    setBlockTime(date);
    setMessage((prevState) => {
      return {
        ...prevState,
        block_time: moment?.utc(date)?.toISOString()
      };
    });
  };

  const updateReminderTime = (date: Date) => {
    if (moment(date).isAfter(blockTime)) {
      toast(t('TOAST_MESSAGES.REMINDER_DATE_SHOULD_NOT_BE_BEFORE_THE_BLOCK_DATE'), 'warning');
      return;
    }
    setReminderTime(date);
    setMessage((prevState) => {
      return {
        ...prevState,
        reminder_time: moment?.utc(date)?.toISOString()
      };
    });
  };

  const checkUserQuota = (newMessage: Message) => {
    const messagesCount = newMessage?.message?.length > 160 ? Math.floor(newMessage?.message?.length / 153) : 1;
    if (addMobile && userQuota?.num_sms < messagesCount) {
      toast(`${t('TOAST_MESSAGES.SMS_QUOTA_ERROR')}`, 'error');
      return false;
    } else if (addWhatsapp && userQuota?.num_whatsapp <= 0) {
      toast(`${t('TOAST_MESSAGES.WHATSAPP_QUOTA_ERROR')}`, 'error');
      return false;
    } else if (addEmail && userQuota?.num_email <= 0) {
      toast(`${t('TOAST_MESSAGES.EMAIL_QUOTA_ERROR')}`, 'error');
      return false;
    }
    return true;
  };

  const validateUserData = () => {
    if (!user?.f_name) {
      toast(`${t('TOAST_MESSAGES.FIRST_NAME_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (!user?.l_name) {
      toast(`${t('TOAST_MESSAGES.LAST_NAME_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (user?.email && !validator.isEmail(user?.email)) {
      toast(`${t('TOAST_MESSAGES.EMAIL_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (user?.mobile && !validator.isMobilePhone(user?.mobile)) {
      toast(`${t('TOAST_MESSAGES.MOBILE_NUMBER_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (user?.whatsapp && !validator.isMobilePhone(user?.whatsapp)) {
      toast(`${t('TOAST_MESSAGES.WHATSAPP_NUMBER_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (addEmail && !user?.email) {
      toast(`${t('TOAST_MESSAGES.EMAIL_ERROR')}`, 'error');
      return false;
    } else if (addMobile && !user?.mobile) {
      toast(`${t('TOAST_MESSAGES.MOBILE_NUMBER_ERROR')}`, 'error');
      return false;
    } else if (addWhatsapp && !user?.whatsapp) {
      toast(`${t('TOAST_MESSAGES.WHATSAPP_NUMBER_ERROR')}`, 'error');
      return false;
    }
    return true;
  };

  const validateMessageData = () => {
    if (!validateUserData()) {
      return false;
    } else if (!message?.subject) {
      toast(`${t('TOAST_MESSAGES.SUBJECT_TIME_ERROR')}`, 'error');
      return false;
    } else if (!blockTime) {
      toast(`${t('TOAST_MESSAGES.BLOCK_TIME_ERROR')}`, 'error');
      return false;
    } else if (!reminderTime) {
      toast(`${t('TOAST_MESSAGES.REMINDER_TIME_ERROR')}`, 'error');
      return false;
    }
    return true;
  };

  const saveMessage = async () => {
    const newMessage = {
      ...message,
      to_user: {
        ...user,
        email: addEmail ? user?.email : '',
        mobile: addMobile ? user?.mobile : '',
        whatsapp: addWhatsapp ? user?.whatsapp : ''
      },
      attachment: attachments?.map((attachment) => {
        return {
          name: attachment?.name,
          mimetype: attachment?.mimetype,
          size: attachment?.size,
          url: attachment?.url
        };
      })
    };
    if (!validateMessageData()) {
      return;
    }
    if (!checkUserQuota(newMessage)) {
      return;
    }
    setLoading(true);
    if (messageId) {
      const response = await updateMessageWithId(messageId, newMessage);
      if (response !== null) {
        const newUserQuota = await updateUserQuotaOnMessageUpdate(userQuota, messageToEdit, newMessage);
        setUserQuota(newUserQuota);
      }
    } else {
      const response = await createMessage(newMessage);
      if (response !== null) {
        const newUserQuota = await updateUserQuotaOnMessageCreation(userQuota, newMessage);
        setUserQuota(newUserQuota);
      }
    }
    setLoading(false);
    navigate('/');
  };

  useEffect(() => {
    const getQuotaForCurrentUser = async () => {
      const uQuota = await getUserQuota();
      if (uQuota) {
        setUserQuota(uQuota as UserQuota);
      }
    };
    getQuotaForCurrentUser();
  }, []);

  useEffect(() => {
    const getMessageToEdit = async () => {
      if (messageId) {
        const response = await getMessageWithId(messageId);
        const msg = response as Message;
        console.log(response);
        setMessage(msg);
        setMessageToEdit(msg);
        setUser(msg.to_user);
        setBlockTime(new Date(msg.block_time));
        setReminderTime(new Date(msg.reminder_time));
        if (Array.isArray(msg?.attachment)) {
          setAttachments(
            msg?.attachment?.map((attachment) => {
              return {
                ...attachment,
                id: `${attachment?.name}_${Date.now()}_${attachment?.size}`
              };
            })
          );
        }
        setAddEmail(!!msg?.to_user?.email);
        setAddMobile(!!msg?.to_user?.mobile);
        setAddWhatsapp(!!msg?.to_user?.whatsapp);
      }
    };
    getMessageToEdit();
  }, [messageId]);

  const removeAttachment = async (attach: Attachment) => {
    if (attach) {
      const filteredAttachments = attachments?.filter((attachment) => attachment?.id !== attach.id);
      setAttachments(filteredAttachments);
      const newUserQuota = await updateQuotaOnFileDeletion(userQuota, attach);
      setUserQuota(newUserQuota);
    }
  };

  const files = attachments?.map((attachment: Attachment) => (
    <li key={attachment?.id} className="d-flex justify-content-between align-items-center">
      <span>
        <FontAwesomeIcon icon={faFile} className="me-3" />
      </span>
      <span className={`flex-fill ${styles.fileName}`}>{attachment?.name}</span>
      <span className={styles.progressWrap}>
        {attachment?.status === 'uploading' ? (
          <ProgressBar className={styles?.progressBar} now={attachment?.progress} />
        ) : (
          <>
            <span>{Math.round(attachment?.size / 1000)}KB</span>
            <span className={styles.trashIcon}>
              <FontAwesomeIcon icon={faTrash} className="me-3" onClick={() => removeAttachment(attachment)} />
            </span>
          </>
        )}
      </span>
    </li>
  ));

  useEffect(() => {
    const uploadFile = (attachmentFile: Attachment) => {
      const currentUser = getUserState();
      const storageRef = ref(storage, `files/${currentUser?.user?.uid}/${attachmentFile?.name}`);
      const uploadTask = uploadBytesResumable(storageRef, attachmentFile?.file as Blob | Uint8Array | ArrayBuffer);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setAttachments((prevState: Attachment[]) => {
            return prevState?.map((attachment) => {
              return attachment?.id === attachmentFile?.id ? { ...attachmentFile, progress: progress } : attachment;
            });
          });
        },
        (error) => {
          console.error(error);
          setAttachments((prevState: Attachment[]) => {
            return prevState?.map((attachment) => {
              return attachment?.id === attachmentFile?.id ? { ...attachmentFile, status: 'error' } : attachment;
            });
          });
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setAttachments((prevState: Attachment[]) => {
              return prevState?.map((attachment) => {
                return attachment?.id === attachmentFile?.id ? { ...attachmentFile, status: 'uploaded', url: downloadURL } : attachment;
              });
            });
            const newUserQuota = await updateQuotaOnFileUpload(userQuota, attachmentFile);
            setUserQuota(newUserQuota);
          });
        }
      );
    };
    if (acceptedFiles?.length) {
      let totalSize = 0;
      acceptedFiles.forEach((file) => {
        totalSize = totalSize + file.size;
      });
      totalSize = totalSize / 100000; // in MBs
      if (userQuota.available_storage - totalSize < 0) {
        toast(t('TOAST_MESSAGES.YOU_DONT_HAVE_ENOUGH_STORAGE_QUOTA_LEFT'), 'error');
        return;
      }
      acceptedFiles.forEach((file) => {
        const attachment: Attachment = {
          id: `${file?.name}_${Date.now()}_${file?.size}`,
          file: file,
          name: file?.name,
          mimetype: file?.type,
          size: file?.size,
          url: '',
          progress: 0,
          status: 'uploading'
        };
        setAttachments((prevState) => [...prevState, attachment]);
        uploadFile(attachment);
      });
    }
  }, [acceptedFiles]);

  const BlockDatePickerInput = forwardRef(({ value, onClick }: any, ref: ForwardedRef<any>) => {
    return (
      <div className={styles.singleButton} onClick={onClick} ref={ref}>
        <button className={`${styles.btn} ${styles.dateText}`}>{value ? value : t('CREATE_MESSAGE.BLOCK')}</button>
        <span className={styles.marginButtons}>
          <img src={calIcon} alt="calendar" />
        </span>
      </div>
    );
  });

  BlockDatePickerInput.displayName = 'BlockDatePickerInput';

  const ReminderDatePickerInput = forwardRef(({ value, onClick }: any, ref: ForwardedRef<any>) => {
    return (
      <div className={styles.singleButton} onClick={onClick} ref={ref}>
        <button className={`${styles.btn} ${styles.dateText}`}>{value ? value : t('CREATE_MESSAGE.REMINDER')}</button>
        <span className={styles.marginButtons}>
          <img src={calIcon} alt="calendar" />
        </span>
      </div>
    );
  });

  ReminderDatePickerInput.displayName = 'ReminderDatePickerInput';

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div className="inner-container create-message-inner-container">
      <div className="container">
        <div className={`row ${styles.mainWrapmessage}`}>
          <div className="row d-flex justify-content-center">
            <div className=" d-flex justify-content-center mb-4">
              <h1 className="text-center page-heading">{t('CREATE_MESSAGE.HEADING')}</h1>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="">
              <div className={styles.singleInput} onClick={() => openUserModel()}>
                <span className={styles.margins}>
                  <img src={nameUser} alt="user" />
                </span>
                <div className={`${styles.margins} ${styles.nameInput} ${message?.to_user?.f_name && styles.hasValue}`}>
                  <span className={styles.nameText}>{message?.to_user?.f_name + (message?.to_user?.l_name ? ` ${message?.to_user?.l_name}` : '') || `${t('CREATE_MESSAGE.ENTER_NAME')}`}</span>
                  <span>
                    {addEmail ? (
                      <span className="mx-1 1">
                        <img src={emailwhiteIcon} width="22px" alt="email" />
                      </span>
                    ) : null}
                    {addMobile ? (
                      <span className="mx-1">
                        <img src={mobilewhiteIcon} alt="mobile" />
                      </span>
                    ) : null}
                    {addWhatsapp ? (
                      <span className="mx-1">
                        <img src={whatsappwhiteIcon} width="22px" alt="whatsapp" />
                      </span>
                    ) : null}
                  </span>
                </div>
                {!message?.to_user?.f_name ? (
                  <span className={styles.margins}>
                    <span className={styles.hover} onClick={() => openUserModel()}>
                      <FontAwesomeIcon size="2x" icon={faPlusCircle} />
                    </span>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <Modal show={showUserModal} onHide={() => setShowUserModal(false)}>
            <Modal.Body className={styles.bgModal}>
              <div className="row d-flex justify-content-center">
                <div className="col-1  p-0" />
                <div className="col-lg-8 col-md-6 col-9 mb-3">
                  <div className={styles.singleInput}>
                    <input type="text" className={styles.margins} placeholder={t('CREATE_MESSAGE.ENTER_FIRST_NAME')} value={user?.f_name || ''} onChange={setFirstName} />
                    <span className={styles.margins}>
                      <img src={popuserIcon} width="22px" alt="user" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-1  p-0" />
                <div className="col-lg-8 col-md-6 col-9 mb-3">
                  <div className={styles.singleInput}>
                    <input type="text" className={styles.margins} placeholder={t('CREATE_MESSAGE.ENTER_LAST_NAME')} value={user?.l_name || ''} onChange={setLastName} />
                    <span className={styles.margins}>
                      <img src={popuserIcon} width="22px" alt="user" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-1  p-0">
                  <div className="form-check ps-0">
                    <input className={`formCheckInput ${styles.formCheckInput}`} type="checkbox" defaultChecked={addEmail} onChange={() => setAddEmail((prevState) => !prevState)} id="flexCheckDefault" />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-9 mb-3">
                  <div className={`${styles.singleInput} ${!addEmail ? 'disabled' : ''}`}>
                    <input type="text" className={styles.margins} placeholder={t('CREATE_MESSAGE.ENTER_EMAIL_ADDRESS')} value={user?.email || ''} onChange={setEmail} disabled={!addEmail} />
                    <span className={styles.margins}>
                      <img src={emailIcon} width="22px" alt="email" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-1  p-0">
                  <div className="form-check ps-0">
                    <input className={`formCheckInput ${styles.formCheckInput}`} type="checkbox" defaultChecked={addMobile} onChange={() => setAddMobile((prevState) => !prevState)} id="flexCheckDefault" />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-9 mb-3 ">
                  <div className={`${styles.singleInput} ${!addMobile ? 'disabled' : ''}`}>
                    <input type="number" className={styles.margins} placeholder={t('CREATE_MESSAGE.ENTER_MOBILE_NUMBER')} value={user?.mobile || ''} onChange={setMobileNumber} disabled={!addMobile} />
                    <span className={styles.margins}>
                      <img src={mobileIcon} alt="mobile" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-1  p-0">
                  <div className="form-check ps-0">
                    <input className={`formCheckInput ${styles.formCheckInput}`} type="checkbox" defaultChecked={addWhatsapp} onChange={() => setAddWhatsapp((prevState) => !prevState)} id="flexCheckDefault" />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-9 mb-3 ">
                  <div className={`${styles.singleInput} ${!addWhatsapp ? 'disabled' : ''}`}>
                    <input type="number" className={styles.margins} placeholder={t('CREATE_MESSAGE.ENTER_WHATSAPP_NUMBER')} value={user?.whatsapp || ''} onChange={setWhatsappNumber} disabled={!addWhatsapp} />
                    <span className={styles.margins}>
                      <img src={whatsappIcon} width="22px" alt="whatsapp" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-1  p-0" />
                <div className="col-lg-8 col-md-6 col-9 mb-3">
                  <div className="row">
                    <div className="col-6">
                      <div className={styles.singleButton}>
                        <button className={styles.btn} onClick={saveUserDetails}>
                          {t('CREATE_MESSAGE.SAVE')}
                        </button>
                        <span className={`pt-0 ${styles.marginPopup}`}>
                          <img src={popupsaveIcon} width="13" alt="save" />
                        </span>
                      </div>
                      \
                    </div>
                    <div className="col-6">
                      <div className={styles.singleButton}>
                        <button className={styles.btn} onClick={() => setShowUserModal(false)}>
                          {t('CREATE_MESSAGE.CANCEL')}
                        </button>
                        <span className={`pt-0 ${styles.marginPopup}`}>
                          <img src={popupcrossIcon} width="13" alt="cancel" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <div className="row d-flex justify-content-center">
            <div className="mt-3">
              <div className={styles.singleInput}>
                <span className={styles.margins}>
                  <img src={subjectIcon} alt="subject" />
                </span>
                <input type="text" className={styles.margins} placeholder={t('CREATE_MESSAGE.SUBJECT')} value={message?.subject || ''} onChange={setMessageSubject} />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="mt-3">
              <h6 className={styles.messageView}>{t('CREATE_MESSAGE.MESSAGE')}:</h6>
              <div className="form-outline">
                <textarea className={`form-control ${styles.textArea}`} id="textAreaExample2" rows={6} value={message?.message || ''} onChange={setMessageText} />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-between">
            <div className="col-sm-6 mt-3">{<DatePicker filterTime={filterPassedTime} customInput={<BlockDatePickerInput />} selected={blockTime} isClearable={false} minDate={new Date()} shouldCloseOnSelect={false} onChange={updateBlockTime} showTimeSelect timeIntervals={360} dateFormat="dd-MM-yyyy hh:mm aa" />}</div>
            <div className="col-sm-6 mt-3">{<DatePicker filterTime={filterPassedTime} customInput={<ReminderDatePickerInput />} selected={reminderTime} isClearable={false} minDate={new Date()} shouldCloseOnSelect={false} onChange={updateReminderTime} showTimeSelect timeIntervals={360} dateFormat="dd-MM-yyyy hh:mm aa" />}</div>
          </div>
          {/*File DROP AREA*/}
          <div className="row d-flex justify-content-center">
            <div className="mt-3">
              <div {...getRootProps({ className: styles.dropzone })}>
                <input {...getInputProps()} />
                <p className={`mb-0 ${styles.dragmessage} `}>{t('CREATE_MESSAGE.DRAG_DROP')}</p>
              </div>
              <aside>
                <ul className={`list-unstyled ${styles.fileslist} mt-3`}>
                  <li>{files}</li>
                </ul>
              </aside>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-4 mt-3">
              <div className={styles.singleButtons} onClick={saveMessage}>
                <button className={styles.btn} disabled={loading}>
                  {t('CREATE_MESSAGE.SAVE')}
                  {loading ? <span className="spinner-border spinner-border-sm" role="status" /> : null}
                </button>
                <span className={styles.marginButtons}>
                  <img src={saveIcon} alt="save" />
                </span>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-sm-4 mt-3">
              <div className={styles.singleButtons} onClick={() => navigate('/')}>
                <button className={styles.btn} disabled={loading}>
                  {t('CREATE_MESSAGE.CANCEL')}
                </button>
                <span className={styles.marginButtons}>
                  <img src={crossIcon} alt="cancel" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateMessage;
