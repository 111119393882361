import React from 'react';
import styles from './PrivacyPolicy.module.scss';

function PrivacyPolicy() {
  return (
    <div className={`${styles.bg_align}`}>
      <div className={`${styles.paragraph_align}`}>
        <h1>Privacy Policy of Owille Application</h1>

        <p>Welcome to Owille. Owille is an Online testament, people can sign in and use the app as security device,to sent messages, timebased, if they are death, they can set a sending date, if they don't react on the push messages, than the app will sent automatic the messages. The app does not provide your information to anyone and will not advertise your location to other users.</p>
        <p>This section pertains to the Privacy Policy of the Owille native mobile applications and website. We would like to inform that our privacy policy is subject to change without intimation and you shall be required to review the same regularly.</p>
        <p>The protection and security of your personal information is one of our top priorities. This Privacy Policy discloses of our practice with respect to the information collected from the users of the Owille Website or native mobile applications.</p>
        <p>By using the Owille Website or native mobile applications you agree to accept the terms of this Privacy Policy as well as the Website and native mobile applications Terms of Use. By accessing or using the Website and native mobile applications you expressly consent to our use and disclosure of your personal information in any manner described in this Privacy Policy. This Privacy Policy extends to both, users who visit the Website or native mobile applications but do not transact business on the Website or native mobile applications as well as users who are registered on the Website or native mobile applications. "Personal Information" refers to any information that identifies or can be used to identify, contact or locate the person, to whom such information pertains including, but not limited to, name, birth date, phone number, email address, other information etc.</p>

        <h4>INFORMATION WE COLLECT</h4>

        <h4>Information you provide:</h4>
        <p>When you register an account or complete a transaction through the Application or Website, we collect some “Personal Information” (that is, any information that can be directly associated with you), such as your name, birth date, email address, phone number, address and other similar information. </p>

        <h4>Device Identifiers:</h4>
        <p>When you access the Owille Application or Website by or through a device, we may access, collect, monitor and/or remotely store one or more “device identifiers”. Device identifiers allow us to uniquely identify your device and are used to enhance the Service (including sending updates and information from the Service). A device identifier does not collect or share any other personally identifiable information about you. A device identifier may also be used in conjunction with other Personal Information.</p>

        <h4>Analytics:</h4>
        <p>When you access our native mobile application or website, we may collect information (either directly or using third party services) using logging and cookies which can sometimes be correlated with Personal Information. We use this information to monitor and analyze use and interest in the Site. See the section on “Service Providers” for more detail in this respect.</p>

        <h4>Log data:</h4>
        <p>As with most mobile application or website and technology services delivered over the Internet, our servers automatically collect information when you access or use our service and record it in log files. This log data may include the Internet Protocol (IP) address, language preferences and cookie data.</p>

        <h4>Cookie Information:</h4>
        <p>A cookie is a text file stored by a User’s web browser, which enables the browser to remember passwords, orders and preferences when visiting a website. We use cookies and similar technologies such as pixel tags and web beacons in our Site that help us collect certain usage data about you (such as your preferences and to process shopping cart items) (“Usage Data”), which may become Personal Information due to direct association with you. The Site may also include cookies and similar tracking technologies of third parties, which may collect information about you via the Site and across other sites and online services, including social media platforms. Our use of cookies and similar technologies such as pixel tags and web beacons is generally for operational purposes, website performance purposes, enhancing functionality of the Site and advertising. For more details about how we use these technologies, please see the section on “Service Providers” below.</p>

        <h4>USE OF INFORMATION</h4>

        <p>We uses information which we collects for the purpose of fulfilling our contractual obligations to you, in furtherance of our legitimate interests in operating our Site and business and/or where you have consented to such usage. We may also provide your name, mailing address and other Personal Information, including purchase history, to a third party data warehouse that may then provide such information to other companies. More specifically, we uses Personal Information:</p>

        <h3>To provide, update, maintain and protect Mobile Application and Website and business:</h3>
        <p>This includes use of Personal Information to support delivery of the mobile application or website, prevent or address service errors, security or technical issues, analyze and monitor usage, trends and other activities or at your request. We may use your email address or phone number to send you notices (including any notices required by law, in lieu of communication by postal mail). If you correspond with us by email, we may retain the content of your email messages, your email address and our responses.</p>

        <h3>As required by applicable law, legal process or regulation:</h3>
        <p>We may in certain instances be compelled by law to process your Personal Information in order to comply with a binding order. We will only do so to the extent reasonably required by that order.</p>

        <h3>To communicate with you by responding to your requests, comments and questions:</h3>
        <p>If you contact us, we may use your Personal Information to respond.</p>

        <h3>To send emails and other communications:</h3>
        <p>We may send you service, technical and other administrative emails, messages and other types of communications. We may also contact you to inform you about changes in our products, and important notices, such as security and fraud notices.</p>

        <h3>For billing, account management and other administrative matters:</h3>
        <p>We may need to contact you for invoicing, account management and similar reasons and we use account data to administer accounts and keep track of billing and payments.</p>

        <h3>To investigate and help prevent security issues and abuse:</h3>
        <h3>For marketing purposes:</h3>
        <p>We may use the information we collect, including Personal Information, to market our products and services to new and existing customers, and to understand our audience. We may also use your Personal Information to send you marketing messages and may provide such information to third parties to send you information about our services. If you are in the EEA we will only do so if you have opted in to receive such communications. If you don’t want to receive these messages, you can opt out by following the instructions in the message or by contacting us as provided at the end of this Privacy Policy.</p>

        <h3>Third Party Service Provider</h3>
        <p>The app does use third party services that may collect information used to identify you.</p>
        <p>Link to privacy policy of third party service providers used by the app</p>
        <ul>
          <li>
            <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">
              Google Play Services
            </a>
          </li>
          <li>
            <a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noreferrer">
              Firebase
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer">
              Facebook
            </a>
          </li>
        </ul>

        <h4>INFORMATION SHARING AND DISCLOSURE</h4>
        <p>We may share and/or disclose your Personal Information to or with third parties in order to provide the services requested by you, and under the following circumstances:</p>
        <p> • We shares certain Personal Information with its payment service provider, email service provider and other third parties necessary in order to fulfill requirement. These third parties are prohibited from using your Personal Information for promotional purposes.</p>
        <p> • We may share Personal Information and other information we collect with our Service Providers and other third parties in connection with our marketing and business development efforts</p>
        <p> • We may share Personal Information to third parties for incorporation into larger databases used for marketing purposes by other third parties, including for such other third parties’ direct marketing to you.</p>
        <p> • We shares non-Personal Information for purposes of better understanding the usage of the mobile application and website by Users and improving on that experience.</p>
        <p> • We reserves the right to disclose Personal Information: as permitted or required by law; when We believes disclosure is necessary to protect our rights or to comply with a legal process; and/or in the event it is necessary to prevent, investigate or take action regarding unlawful, illegal or suspicious activities that violate the Terms of Use or this Privacy Policy.</p>
        <p> • We may transfer Personal Information to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by us about our Users is among the assets transferred.</p>
        <p>All information voluntarily shared by you through forums, comments, or blog posts is publicly available and your username may be visible by other Users. We are not responsible for any information you submit that can be read by other Users and can be used to send you unsolicited information by other Users.</p>
        <p>We might, from time to time, choose to post certain Personal Information about a User for promotional or Site purposes; prior to posting said information, we will obtain the User’s consent via email.</p>

        <h4>RETENTION OF DATA</h4>
        <p>We will retain your Personal Information for as long as is reasonable or appropriate for the purposes set out in this Privacy Policy. We will also retain and use your Personal Information to the extent necessary or reasonable to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>

        <h4>TRANSFER OF DATA</h4>
        <p>Your information, including Personal Information, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to those transfers.</p>
        <p>We will take steps reasonably designed to treat your Personal Information securely and in accordance with this Privacy Policy, as further described below.</p>

        <h4>YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION (GDPR)</h4>
        <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. We aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Information.</p>
        <p>If you wish to be informed about what Personal Information we hold about you, and/or if you want it to be removed from our systems, please contact us using the contact information set out below.</p>
        <p>In certain circumstances, where we act as Data Controller (as defined in the GDPR), you have the following data protection rights:</p>
        <p> • Request access to your Personal Information. (commonly known as a "data subject access request"). This enables you to receive a copy of the Personal Information we hold about you where we are the data controller and to check that we are lawfully processing it.</p>
        <p> • Request correction of the Personal Information that we hold about you. This enables you to have any incomplete or inaccurate information we hold about you corrected, though we may need to verify the accuracy of the new information you provide to us.</p>
        <p> • Request erasure of your Personal Information. This enables you to ask us to delete or remove Personal Information where there is no good reason for us to continue to process it. You also have the right to ask us to delete or remove your Personal Information where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your Personal Information to comply with local law. Note, however, that we may not always be able to comply with your request for erasure for specific legal reasons, which will be explained to you, if applicable, at the time of your request.</p>
        <p> • Object to processing of your Personal Information. where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground, as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your Personal Information for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
        <p> • Request restriction of processing of your Personal Information. This enables you to ask us to suspend the processing of your Personal Information in the following scenarios: (a) if you want us to establish the information's accuracy; (b) where our use of the information is unlawful but you do not want us to erase it; (c) where you need us to hold the information even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your information but we need to verify whether we have overriding legitimate grounds to use it.</p>
        <p> • Request the transfer of your Personal Information to you or to a third party. We will provide to you, or a third party you have chosen, your Personal Information in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
        <p> • Withdraw consent at any time where we are relying on consent to process your Personal Information. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
        <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Information. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
        <p>If you wish to exercise any of the rights set out above, please contact us using the contact details below.</p>
        <p>You will not have to pay a fee to access your Personal Information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in such circumstances.</p>
        <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal information (or to exercise any of your other rights). This is a security measure designed to ensure that Personal Information is not disclosed to any person who has no right to receive it.</p>
        <p>We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

        <h4>CALIFORNIA PRIVACY RIGHTS</h4>
        <p>California Civil Code Section 1798.83, known as the “Shine the Light” law, permits our users who are California residents to request and obtain from us a list of what Personal Information (if any) we disclosed to third parties for their own direct marketing purposes in the preceding calendar year and the names and addresses of those third parties. Requests may be made only once per year per person, must be sent to the email address below, and are free of charge. We do not disclose Personal Information protected under the “Shine the Light” law to third parties for their own direct marketing purposes.</p>

        <h4>CHILDREN’S PRIVACY</h4>
        <p>Our Service is not bound for any age. If childern are using application then their gaudian need to take care about the information share with us.</p>
        <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from children without verification of parental consent, we take steps to remove that information from our servers.</p>

        <h4>PROTECTING YOUR INFORMATION</h4>
        <p>We implements security measures designed to maintain the security of your Personal Information. These security measures are implemented both during transmission of Personal Information and once received. The security of your Personal Information is important to us. Certain sensitive information submitted, such as credit card information, is encrypted using secure layer technology (SSL). However, no method of safeguarding information is completely secure. While we use measures designed to protect Personal Information, we cannot guarantee that our safeguards will be effective or sufficient. In addition, you should be aware that Internet data transmission is not always secure, and we cannot warrant that information you transmit utilizing the Site is or will be secure.</p>

        <h4>COMMUNICATIONS OPT-OUT</h4>

        <p>You may opt out of receiving marketing or other communications from us at any time by following the opt-out link or other unsubscribe instructions provided in any email message received, or by contacting us using the contact information provided below. If you wish to opt out by sending us an email to the address provided below, please include “Opt-Out” in the email’s subject line and include your name and the email address you used to sign up for communications in the body of the email. Note that, even if you opt out of receiving marketing communications from us, we will still send you order confirmations and other non-marketing related messages.</p>

        <h4>CHANGES TO PRIVACY POLICY</h4>
        <p>We may amend or update from time to time this Privacy Policy in its sole discretion and without prior notice. Any such amendments or updates will be effective upon our posting of the revised Privacy Policy. Your continued use of the Site following our posting of any revised Privacy Policy will constitute your acknowledgement and acceptance of the amended Privacy Policy.</p>

        <h4>EFFECTIVE DATE</h4>
        <p>This policy was last modified 13/06/2022.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions, please contact us.</p>
        <p>Owille Application Team</p>
        <p>Email: testament@owille.com</p>
        <p>Website: www.owille.com</p>
        <p>Contact Number: +491633941368</p>
        <p>Address: Downmap, GmbH, stresmann str 76,28207 Bremen, Germany</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
