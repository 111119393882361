import { Message, User, UserQuota, UserSettings } from '../types';

const EMPTY_USER: User = {
  f_name: '',
  l_name: '',
  email: '',
  mobile: '',
  whatsapp: ''
};

const EMPTY_MESSAGE: Message = {
  id: '',
  to_user: EMPTY_USER,
  subject: '',
  message: '',
  attachment: [],
  block_time: '',
  reminder_time: '',
  created_by_user: '',
  created_time: '',
  updated_time: '',
  message_sent: false,
  blocked: false
};

const EMPTY_USER_QUOTA: UserQuota = {
  _id: '',
  num_sms: 0,
  num_email: 0,
  num_whatsapp: 0,
  available_storage: 0,
  total_storage_purchased: 0
};

const DEFAULT_USER_QUOTA: UserQuota = {
  _id: '',
  num_sms: 10,
  num_email: 1000,
  num_whatsapp: 20,
  available_storage: 5000,
  total_storage_purchased: 5000
};

const DEFAULT_USER_SETTINGS: UserSettings = {
  _id: '',
  language: 'en',
  twoFactorAuth: false,
  block: {
    days: 0,
    months: 0,
    years: 0
  },
  reminder: {
    days: 0,
    months: 0,
    years: 0
  },
  created_time: '',
  updated_time: ''
};

export { EMPTY_USER, EMPTY_MESSAGE, EMPTY_USER_QUOTA, DEFAULT_USER_QUOTA, DEFAULT_USER_SETTINGS };
