import React, { useEffect, useState } from 'react';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Payments.module.scss';
import innerLogo2 from 'assets/images/inner-logo2.png';
import { useTranslation } from 'react-i18next';
import { createCheckoutSession, getPricingPlans } from 'services/payments';
import { openLink, toast } from 'helpers/utils';
import { getPaymentsState, setPaymentsState } from 'state_management/localStates';
import { getPaymentDetails } from 'services/users';

function Payments() {
  const { t } = useTranslation();
  const [plans, setPlans] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);

  useEffect(() => {
    const getPlans = async () => {
      try {
        setLoading(true);
        const data = await getPricingPlans();
        data.sort((a: any, b: any) => {
          const priceOfA = a && a.prices && a.prices[0] && a.prices[0].unitAmount;
          const priceOfB = b && b.prices && b.prices[0] && b.prices[0].unitAmount;
          return priceOfA - priceOfB;
        });
        const paymentsDetails: any = await getPaymentDetails();
        setPaymentsState(paymentsDetails);
        setCurrentSubscription(paymentsDetails && paymentsDetails.subscription);
        setPlans(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast(t('TOAST_MESSAGES.FAILED_TO_FETCH_PAYMENT_PLAN'), 'error');
      }
    };
    getPlans();
  }, []);

  const checkout = async (priceId: string) => {
    try {
      if (currentSubscription?.priceId) return;
      const paymentsState = getPaymentsState();
      const body = {
        stripeCustomerId: paymentsState?.customer_id,
        successUrl: `${window.location.origin}/payment-success`,
        cancelUrl: window.location.href,
        priceId
      };
      setCreatingSession(true);
      const url = await createCheckoutSession(body);
      setCreatingSession(false);
      if (url) {
        openLink(url, '_self');
      }
    } catch (err) {
      toast(t('TOAST_MESSAGES.FAILED_TO_CREATE_CHECKOUT_SESSION'), 'error');
    }
  };

  return (
    <div>
      <div className="inner-logo-2">
        {' '}
        <img src={innerLogo2} className="img-fluid" alt="logo" />
      </div>
      <div className="inner-container" hidden={loading}>
        <div className="container inner-box">
          <h1 className="text-center page-heading">{t('PAYMENTS.HEADING')}</h1>

          <div className={`mt-4 table-responsive ${styles.tableresponsive}`}>
            <table className={`table table-bordered ${styles.paymenttable}`}>
              <thead>
                <tr>
                  <th className={styles.paymenttitle} />
                  {plans?.map((plan: any) => {
                    return (
                      <th key={plan?.name} className={`${styles.paymenttitle}`}>
                        <h4>{plan?.name}</h4>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr className={styles.pricebox}>
                  <td>
                    <h5>{t('PAYMENTS.PRICE')}</h5>
                  </td>
                  {plans?.map((plan: any) => {
                    return (
                      <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>
                        <h5 className="panel-title price">${plan?.prices[0]?.unitAmount / 100}</h5>
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <td className={styles.pricebox}>
                    <h5>{t('PAYMENTS.SMS')}</h5>
                  </td>
                  {plans?.map((plan: any) => {
                    return (
                      <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>
                        <FontAwesomeIcon icon={!isNaN(plan?.metadata?.sms) && Number(plan?.metadata?.sms) > 0 ? faSquareCheck : faSquare} />
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className={styles.pricebox}>
                    <h5>{t('PAYMENTS.EMAIL')}</h5>
                  </td>
                  {plans?.map((plan: any) => {
                    return (
                      <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>
                        <FontAwesomeIcon icon={!isNaN(plan?.metadata?.email) && Number(plan?.metadata?.email) > 0 ? faSquareCheck : faSquare} />
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className={styles.pricebox}>
                    <h5>{t('PAYMENTS.WHATSAPP')}</h5>
                  </td>
                  {plans?.map((plan: any) => {
                    return (
                      <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>
                        <FontAwesomeIcon icon={!isNaN(plan?.metadata?.whatsapp) && Number(plan?.metadata?.whatsapp) > 0 ? faSquareCheck : faSquare} />
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className={styles.pricebox}>
                    <h5>{t('PAYMENTS.STORAGE')}</h5>
                  </td>
                  {plans?.map((plan: any) => {
                    return (
                      <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>
                        {!isNaN(plan?.metadata?.storage) ? (Number(plan?.metadata?.storage) < 1024 ? `${Number(plan?.metadata?.storage)}MB` : `${Number(plan?.metadata?.storage) / 1024}GB`) : '0'}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className={styles.pricebox}>
                    <h5>{t('PAYMENTS.ADD_MORE')} </h5>
                  </td>
                  {plans?.map((plan: any) => {
                    return <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>{`${plan?.metadata?.email} ${t('PAYMENTS.EMAIL')}s, ${plan?.metadata?.whatsapp} ${t('PAYMENTS.WHATSAPP')} and ${plan?.metadata?.sms} ${t('PAYMENTS.SMS')}`}</td>;
                  })}
                </tr>
                <tr className="text-center">
                  <td className={styles.pricebox} />
                  {plans?.map((plan: any) => {
                    return (
                      <td key={plan?.name} className={`${currentSubscription?.priceId && currentSubscription?.priceId !== plan?.prices[0]?.id ? 'disabled' : ''}`}>
                        <button className={`${styles.paymentsplanbutton} ${currentSubscription?.priceId === plan?.prices[0]?.id && styles.current}`} onClick={() => checkout(plan?.prices[0]?.id)}>
                          {currentSubscription?.priceId === plan?.prices[0]?.id ? `${t('PAYMENTS.CURRENT_PLAN')}` : `${t('PAYMENTS.CHOOSE_PLAN')}`}
                        </button>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {loading || creatingSession ? (
        <div className="text-center loadingSpinner text-light">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t('LOADING')}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Payments;
