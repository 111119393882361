import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './TwoFactorAuth.module.scss';
import { useTranslation } from 'react-i18next';

function TwoFactorAuth() {
  const { t } = useTranslation();

  return (
    <div className="container login-box">
      <div className={`row ${styles.mainWrap}`}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-md-6 col-9">
            <div className={styles.singleInput}>
              <span className={styles.margins}>
                <FontAwesomeIcon size="lg" color="lightpink" icon={faLock} />
              </span>
              <input type="email" className={styles.margins} placeholder="Enter code" />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-2 col-md-3 col-4 mt-2 p-0">
            <button className={styles.singleButton}>
              <p className={styles.forgotPasswordP}>Verify Your Email</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorAuth;
