import React from 'react';
import innerLogo2 from 'assets/images/inner-logo2.png';
import { useTranslation } from 'react-i18next';
import howToUseGif from 'assets/images/how-to-use2.gif';
import styles from './HowToUse.module.scss';

function HowToUse() {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div className="inner-logo-2">
          <img src={innerLogo2} className="img-fluid" alt="logo" />
        </div>
        <div className={`inner-container`}>
          <div className="container inner-box">
            <h1 className="text-center page-heading mb-5">{t('HOW_TO_USE.HEADING')}</h1>
            <div>
              <p className="text-white">{t('HOW_TO_USE.DESCRIPTION')}</p>
            </div>
            <div className={`${styles.imgWrap}`}>
              <img className="shadow" src={howToUseGif} alt="how to use" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToUse;
