import React, { useCallback, useEffect, useState } from 'react';
import styles from './Notifications.module.scss';
import innerLogo2 from 'assets/images/inner-logo2.png';
import { Notification } from 'types';
import { getFCMToken, onMessageListener } from '../../firebase';
import { Toast } from 'react-bootstrap';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getUserNotifications } from 'services/notifications';
import { toast } from 'helpers/utils';
import { reschedule } from 'services/messages';

function Notifications() {
  const { t } = useTranslation();
  const [checkingToken, setCheckingToken] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotification, setNewNotification] = useState<any>(null);
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);

  const getNotifications = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getUserNotifications();
      const filteredNotifications = Array.isArray(response) ? response?.filter((notif) => notif?.show_notification) : [];
      setNotifications(filteredNotifications);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast(t('TOAST_MESSAGES.FAILED_TO_FETCH_NOTIFICATIONS'), 'error');
      setLoading(false);
    }
  }, []);

  /*  const checkFCMToken = useCallback(async () => {
    try {
      setCheckingToken(true);
      await getFCMToken(setTokenFound);
      setCheckingToken(false);
    } catch (err) {
      console.log(err);
      setCheckingToken(false);
    }
  }, []); */

  useEffect(() => {
    //checkFCMToken();
    getNotifications();
  }, [getNotifications]);

  onMessageListener()
    .then((payload: any) => {
      setShow(true);
      console.log('notification received: ', payload);
      setNewNotification({
        id: payload?.messageId,
        title: payload?.notification?.title,
        body: payload?.notification?.body
      });
      rescheduleMessages();
    })
    .catch((err) => console.log('failed: ', err));

  const rescheduleMessages = async () => {
    try {
      setLoading(true);
      await reschedule();
      const response = await getUserNotifications();
      setNotifications(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const noNotificationFound = () => {
    return (
      <div className={styles.alertWrap}>
        <Alert color="warning">{t('NOTIFICATIONS.NEED_NOTIFICATIONS_PERMISSION')}❗</Alert>
      </div>
    );
  };

  const notificationsList = () => {
    return (
      <>
        <div className="inner-container">
          <div className="container-md inner-box">
            <h1 className="text-center page-heading"> {t('NOTIFICATIONS.HEADING')} </h1>
            {notifications?.length ? (
              notifications?.map((notif) => {
                return (
                  <div key={notif?.message_id} className={`d-flex ${styles.notifactionrow}`} onClick={rescheduleMessages}>
                    <div className="text-white">
                      {/*<h4>{notif?.title}</h4>*/}
                      <p>{notif?.message}</p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className={`text-white text-center mt-4 ${styles.nonotifaction}`}>
                <h4 className="mt-4 mb-4">{t('NOTIFICATIONS.NO_NOTIFICATIONS_FOUND')}</h4>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="inner-logo-2">
        <img src={innerLogo2} className="img-fluid" alt="logo" />x
      </div>
      {loading || checkingToken ? (
        <div className="text-center loadingSpinner text-light">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t('LOADING')}</span>
          </div>
        </div>
      ) : (
        notificationsList()
      )}
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={3000}
        autohide
        animation
        onClick={rescheduleMessages}
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          minWidth: 200
        }}>
        <Toast.Header>
          <strong>{newNotification?.title}</strong>
          <small>{t('NOTIFICATIONS.JUST_NOW')}</small>
        </Toast.Header>
        <Toast.Body>{newNotification?.body}</Toast.Body>
      </Toast>
    </>
  );
}

export default Notifications;
