import { Attachment, Message, UserQuota } from 'types';
import { updateUserQuota } from './messages';

const updateUserQuotaOnMessageCreation = async (userQuota: UserQuota, newMessage: Message) => {
  try {
    let newUserQuota = { ...userQuota };
    if (newMessage) {
      if (newMessage.to_user?.mobile) {
        const messagesCount = newMessage?.message?.length > 160 ? Math.floor(newMessage?.message?.length / 153) : 1;
        newUserQuota = {
          ...newUserQuota,
          num_sms: newUserQuota.num_sms - messagesCount
        };
      }
      if (newMessage.to_user?.whatsapp) {
        newUserQuota = {
          ...newUserQuota,
          num_whatsapp: newUserQuota.num_whatsapp - 1
        };
      }
      if (newMessage.to_user?.email) {
        newUserQuota = {
          ...newUserQuota,
          num_email: newUserQuota.num_email - 1
        };
      }
      await updateUserQuota(newUserQuota);
    }
    return newUserQuota;
  } catch (err) {
    console.log('Failed to update user quota, error: ', err);
    return userQuota;
  }
};

const updateUserQuotaOnMessageUpdate = async (userQuota: UserQuota, messageToEdit: Message, newMessage: Message) => {
  try {
    let newUserQuota = { ...userQuota };
    if (!messageToEdit.to_user?.mobile && newMessage.to_user?.mobile) {
      const messagesCount = newMessage?.message?.length > 160 ? Math.floor(newMessage?.message?.length / 153) : 1;
      newUserQuota = {
        ...newUserQuota,
        num_sms: newUserQuota.num_sms - messagesCount
      };
    }
    if (!messageToEdit.to_user?.whatsapp && newMessage.to_user?.whatsapp) {
      newUserQuota = {
        ...newUserQuota,
        num_whatsapp: newUserQuota.num_whatsapp - 1
      };
    }
    if (!messageToEdit.to_user?.email && newMessage.to_user?.email) {
      newUserQuota = {
        ...newUserQuota,
        num_email: newUserQuota.num_email - 1
      };
    }
    await updateUserQuota(newUserQuota);
    return newUserQuota;
  } catch (err) {
    console.log('Failed to update user quota, error: ', err);
    return userQuota;
  }
};

const updateQuotaOnFileUpload = async (userQuota: UserQuota, attachment: Attachment) => {
  try {
    const fileSizeInMBs = attachment.size / 1000000;
    const newUserQuota = {
      ...userQuota,
      available_storage: userQuota.available_storage - fileSizeInMBs
    };
    await updateUserQuota(newUserQuota);
    return newUserQuota;
  } catch (err) {
    console.log('Failed to update user quota, error: ', err);
    return userQuota;
  }
};

const updateQuotaOnFileDeletion = async (userQuota: UserQuota, attachment: Attachment) => {
  try {
    const fileSizeInMBs = attachment.size / 1000000;
    const newUserQuota = {
      ...userQuota,
      available_storage: userQuota.available_storage + fileSizeInMBs
    };
    await updateUserQuota(newUserQuota);
    return newUserQuota;
  } catch (err) {
    console.log('Failed to update user quota, error: ', err);
    return userQuota;
  }
};

export { updateUserQuotaOnMessageCreation, updateUserQuotaOnMessageUpdate, updateQuotaOnFileUpload, updateQuotaOnFileDeletion };
