import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import innerLogo2 from 'assets/images/inner-logo2.png';
import UserIcon from 'assets/images/usericon.png';
import editIcon from 'assets/images/editicon.png';
import trashIcon from 'assets/images/trashicon.png';
import { Message } from 'types';
import { deleteMessageWithId, getMessagesForCurrentUser } from 'services/messages';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'helpers/utils';

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const getMessages = async () => {
      setLoading(true);
      const response = await getMessagesForCurrentUser();
      setMessages(response);
      setLoading(false);
    };
    getMessages();
  }, []);

  const deleteMessage = async (message: Message) => {
    if (message?.blocked) {
      toast("This message can't be deleted", 'error');
      return;
    }
    await deleteMessageWithId(message.id);
    const response = await getMessagesForCurrentUser();
    setMessages(response);
  };

  return (
    <div>
      <div className="inner-logo-2">
        <img src={innerLogo2} className="img-fluid" alt="logo" />
      </div>
      <div className="inner-container" hidden={loading}>
        <div className="container inner-box">
          <h1 className="text-center page-heading mb-5">{t('HOME.HEADING')}</h1>
          {messages?.length ? (
            messages?.map((message) => {
              return (
                <div key={message?.id} className={`mb-3 ${styles.homemessagerow}`}>
                  <div className="d-flex">
                    <img src={UserIcon} className={`me-5 ${styles.usericon} `} alt="user" />
                    <div className="align-self-start message-block">
                      <h4 className={styles.homemessageheading}>
                        {t('HOME.SUBJECT')}: {message?.subject}
                      </h4>
                      <div className="d-flex">
                        <p>{`${t('HOME.SEND_TIME')}: ${moment(message?.block_time).format('DD-MM-yyyy')}`}</p>
                        <p className="mx-3">{`${t('HOME.REMAINING_TIME_TO_SEND')}: ${moment(message?.reminder_time).diff(moment(new Date()), 'days')} days`}</p>
                      </div>
                      <div className="d-flex">
                        <p>{`${t('HOME.REMINDER_TIME')}: ${moment(message?.block_time).format('DD-MM-yyyy')}`}</p>
                        <p className="mx-3">{`${t('HOME.REMAINING_REMINDER_TIME')}: ${moment(message?.reminder_time).diff(moment(new Date()), 'days')} days`}</p>
                      </div>
                    </div>
                    <div className={`ms-auto ${styles.messageeditdelete}`}>
                      <button className={styles.edit} onClick={() => navigate(`/edit-message/${message?.id}`)}>
                        <img className={styles.edittrash} src={editIcon} alt="icon" />
                      </button>
                      <button className={styles.trash} onClick={() => deleteMessage(message)}>
                        <img className={styles.edittrash} src={trashIcon} alt="icon" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className={`text-white text-center mt-4 ${styles.noMessage}`}>
              <h4 className="mt-4 mb-4">{t('NO_MESSAGE_FOUND')}</h4>
            </div>
          )}
          <div className={`mb-5 ms-auto ${styles.addmoreeditmessage}`} onClick={() => navigate('/create-message')}>
            <button>
              <FontAwesomeIcon icon={faPlus} />{' '}
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="text-center loadingSpinner text-light">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t('LOADING')}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Home;
