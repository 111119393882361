import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './SideBar.module.scss';
import { auth } from '../../../firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';
import homeIcon from 'assets/images/h-icon.png';
import messageIcon from 'assets/images/message.png';
import notificationsIcon from 'assets/images/Notifications.png';
import blockIcon from 'assets/images/Block.png';
import paymentIcon from 'assets/images/Payment.png';
import logoutIcon from 'assets/images/logout.png';
import innerLogo2 from 'assets/images/inner-logo2.png';
import settingsLogo from 'assets/images/settings-logo.png';
import { useTranslation } from 'react-i18next';
import config from 'config.json';

function SideBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const logout = async () => {
    try {
      const result = await signOut(auth);
      navigate('/logout');
      setTimeout(() => {
        localStorage.clear();
        navigate('/login');
      }, 2000);
      console.log(`[INFO] ${result}`);
    } catch (error) {
      console.log(`[ERROR] ${error}`);
    }
  };

  return (
    <>
      <div className={`${styles.sidebar} ${collapsed && styles.collapsed}`}>
        <div className={`inner-logo-2 ${styles.innerlogod}`}>
          <img src={innerLogo2} className="img-fluid" alt="logo" />
        </div>
        <ul>
          <li>
            <Link to={'/'}>
              <img src={homeIcon} alt="home" />
              <span>{t('SIDEBAR.HOME')} </span>
            </Link>
          </li>
          <li>
            <Link to={'/create-message'}>
              <img src={messageIcon} alt="message" />
              <span>{t('SIDEBAR.MESSAGE')}</span>
            </Link>
          </li>
          <li>
            <Link to={'/notifications'}>
              <img src={notificationsIcon} alt="notifications" />
              <span>{t('SIDEBAR.NOTIFICATIONS')}</span>
            </Link>
          </li>
          <li>
            <Link to={'/block'}>
              <img src={blockIcon} alt="block" />
              <span>{t('SIDEBAR.BLOCK')} </span>
            </Link>
          </li>
          {config?.showPayments ? (
            <li>
              <Link to={'/payments'}>
                <img src={paymentIcon} alt="payments" />
                <span>{t('SIDEBAR.PAYMENT')} </span>
              </Link>
            </li>
          ) : null}
          {config?.showSettings ? (
            <li>
              <Link to={'/settings'}>
                <img src={settingsLogo} alt="settings" />
                <span>{t('SIDEBAR.SETTINGS')} </span>
              </Link>
            </li>
          ) : null}
          <li>
            <button className={styles.logoutBtn} onClick={logout}>
              <img src={logoutIcon} alt="logout" />
              <span>{t('SIDEBAR.LOGOUT')} </span>
            </button>
          </li>
        </ul>
        <div className={styles.pushbutton} onClick={() => setCollapsed((prevState) => !prevState)}>
          <button>
            <i className={`fas ${collapsed ? 'fa-chevron-right' : 'fa-chevron-left'}`} />
          </button>
        </div>
      </div>
    </>
  );
}

export default SideBar;
