import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styles from './ForgotPassword.module.scss';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { toast } from '../../helpers/utils';
import validator from 'validator';
import { useTranslation } from 'react-i18next';

type Props = {
  showLoginHint: boolean;
};

function ForgotPassword({ showLoginHint }: Props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);

  const resetPasswordRequest = async () => {
    if (!email) {
      toast(`${t('TOAST_MESSAGES.EMAIL_ADDRESS_ERROR')}`, 'error');
      return;
    } else if (!validator.isEmail(email)) {
      toast(`${t('TOAST_MESSAGES.EMAIL_ADDRESS_SYNTAX_ERROR')}`, 'error');
      return;
    }
    setSending(true);
    try {
      await sendPasswordResetEmail(auth, email);
      console.log(`[INFO] Email sent.`);
      setSending(false);
      toast(`${t('TOAST_MESSAGES.RESET_PASSWORD_INBOX_ERROR')}`, 'success');
    } catch (error: any) {
      console.log(`[ERROR] ${error}`);
      toast(error.message, 'error');
      setSending(false);
    }
  };

  return (
    <div className="container login-box">
      <div className={`row ${styles.mainWrap}`}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-md-6 col-9">
            <div className={styles.singleInput}>
              <span className={styles.margins}>
                <FontAwesomeIcon size="lg" color="lightpink" icon={faLock} />
              </span>
              <input type="email" className={styles.margins} placeholder={t('FORGOT_PASSWORD.EMAIL')} onChange={(event) => setEmail(event?.target?.value)} />
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-2 col-md-3 col-4 mt-2 p-0">
            <button className={styles.singleButton} onClick={resetPasswordRequest} disabled={sending}>
              <p className={styles.forgotPasswordP}>{t('FORGOT_PASSWORD.RESET_PASSWORD')}</p>
              {sending ? <span className="spinner-border spinner-border-sm mx-3" role="status" /> : null}
            </button>
          </div>
        </div>
        {showLoginHint ? (
          <div className="text-white d-flex justify-content-center">
            {t('FORGOT_PASSWORD.REMEMBER_YOUR_ACCOUNT?')}&nbsp;
            <Link to={'/login'}>
              <p>{t('FORGOT_PASSWORD.LOGIN')}</p>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ForgotPassword;
