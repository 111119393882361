import React, { useEffect } from 'react';
import { BrowserRouter,HashRouter, Outlet, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Home, BlockList, CreateMessage, Notifications, Payments, ForgotPassword, Login, Signup, Settings, Logout, PaymentSuccess, HowToUse, TwoFactorAuth, TermsofService, PrivacyPolicy } from 'components';
import { SideBar } from 'components/common';
import { getUserState } from 'state_management/localStates';
import { getUserDetails } from 'state_management/helpers';

function StatefulApp() {
  useEffect(() => {
    const checkUser = async () => {
      const authUser = getUserState();
      if (authUser?.user) {
        await getUserDetails();
      }
    };
    checkUser();
  }, []);

  return (
    <HashRouter>
      {/*<NavBar />*/}
      <Routes>
        <Route path="/termsofservice" element={<TermsofService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route
          path="/"
          element={
            <AuthenticatedRoute>
              <>
                <SideBar />
                <Outlet />
              </>
            </AuthenticatedRoute>
          }>
          <Route
            path="/"
            element={
              <AuthenticatedRoute>
                <Home />
              </AuthenticatedRoute>
            }
          />

          <Route path="/" element={<Home />} />
          <Route
            path="block"
            element={
              <AuthenticatedRoute>
                <BlockList />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="create-message"
            element={
              <AuthenticatedRoute>
                <CreateMessage />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="edit-message/:messageId"
            element={
              <AuthenticatedRoute>
                <CreateMessage />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <AuthenticatedRoute>
                <Notifications />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="change-password"
            element={
              <AuthenticatedRoute>
                <ForgotPassword showLoginHint={false} />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="payment-success"
            element={
              <AuthenticatedRoute>
                <PaymentSuccess />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="logout"
            element={
              <AuthenticatedRoute>
                <Logout />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="how-to-use"
            element={
              <AuthenticatedRoute>
                <HowToUse />
              </AuthenticatedRoute>
            }
          />
        </Route>
        <Route
          path="forgot-password"
          element={
            <UnAuthenticatedRoute>
              <ForgotPassword showLoginHint={true} />
            </UnAuthenticatedRoute>
          }
        />

        <Route
          path="login"
          element={
            <UnAuthenticatedRoute>
              <Login />
            </UnAuthenticatedRoute>
          }
        />
        <Route
          path="signup"
          element={
            <UnAuthenticatedRoute>
              <Signup />
            </UnAuthenticatedRoute>
          }
        />
        <Route
          path="authenticate"
          element={
            <UnAuthenticatedRoute>
              <TwoFactorAuth />
            </UnAuthenticatedRoute>
          }
        />
        {/*<Route path="about-us" element={<AboutUs />} />*/}
        {/*<Route path="services" element={<Services />} />*/}
      </Routes>
    </HashRouter>
  );
}

function AuthenticatedRoute({ children }: { children: JSX.Element }) {
  const authUser = getUserState();
  const location = useLocation();
  if (!authUser?._tokenResponse?.idToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

function UnAuthenticatedRoute({ children }: { children: JSX.Element }) {
  const authUser = getUserState();
  if (authUser?._tokenResponse?.idToken) {
    return <Navigate to="/" />;
  }
  return children;
}

export default StatefulApp;
