import React from 'react';
import { ToastContainer } from 'react-toastify';
import './assets/scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import LastBottom from './assets/images/last-bottom.png';
import CenterBottom from './assets/images/center-bottom.png';
import TopBottom from './assets/images/top-bottom.png';
import './App.scss';
import StatefulApp from './components/StatefulApp/StatefulApp';

function App() {
  return (
    <>
      <StatefulApp />
      <div className="bottom-last w-100">
        <img src={LastBottom} className="img-fluid w-100" alt="last-bottom-img" />
      </div>
      <div className="bottom-center w-100">
        <img src={CenterBottom} className="img-fluid w-100" alt="bottom-center-img" />
      </div>
      <div className="top-bottom w-100">
        <img src={TopBottom} className="img-fluid w-100" alt="top-bottom-img" />
      </div>
      <ToastContainer limit={3} newestOnTop={true} />
    </>
  );
}

export default App;
