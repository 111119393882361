import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, EmailAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const Firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(Firebase);
export const database = getDatabase(Firebase);

export const storage = getStorage(Firebase);
export const messaging = getMessaging(Firebase);

export const Providers = {
  google: new GoogleAuthProvider(),
  email: new EmailAuthProvider(),
  facebook: new FacebookAuthProvider()
};

export const getFCMToken = (setTokenFound: any) => {
  return getToken(messaging, {
    vapidKey: 'BPKUAzuzmWzZOEH4hrpnrW3Ajfk0TPtDQBntRnslMuTAGMkwqXDSP54UmAUyTLFPgGKKA0Fwq6rIpiblvM85BNE'
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const auth = getAuth(Firebase);
export default Firebase;
