import { getPaymentDetails, getUserSettings } from '../services/users';
import { setPaymentsState, setUserSettingsState } from './localStates';

const getUserDetails = async () => {
  const userSettings = await getUserSettings();
  setUserSettingsState(userSettings);
  const paymentsDetails = await getPaymentDetails();
  setPaymentsState(paymentsDetails);
};

export { getUserDetails };
