import React, { FormEvent, useCallback, useState } from 'react';
import styles from './Signup.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { prevent, toast } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { createUserSettings } from 'services/users';

function Signup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [registering, setRegistering] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const isValidForm = useCallback(() => {
    if (!name) {
      toast(`${t('TOAST_MESSAGES.NAME_ERROR')}`, 'error');
      return false;
    } else if (!email) {
      toast(`${t('TOAST_MESSAGES.EMAIL_ERROR')}`, 'error');
      return false;
    } else if (!validator.isEmail(email)) {
      toast(`${t('TOAST_MESSAGES.EMAIL_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (!password) {
      toast(`${t('TOAST_MESSAGES.PASSWORD_ERROR')}`, 'error');
      return false;
    } else if (password?.length < 8) {
      toast(`${t('TOAST_MESSAGES.PASSWORD_SYNTAX_ERROR')}`, 'error');
      return false;
    } else if (confirmPassword !== password) {
      toast(`${t('TOAST_MESSAGES.CONFIRM_PASSWORD_ERROR')}`, 'error');
      return false;
    }
    return true;
  }, [name, email, password, confirmPassword, t]);

  const signUpWithEmailAndPassword = async (event: FormEvent) => {
    prevent(event);
    if (!isValidForm()) {
      return;
    }
    setRegistering(true);
    try {
      const response = await createUserWithEmailAndPassword(auth, email, password);
      if (response?.user?.uid) {
        await createUserSettings(response?.user?.uid);
      }
      setRegistering(false);
      navigate('/login');
    } catch (error: any) {
      console.error(`[ERROR] ${error}`);
      if (error.code.includes('auth/weak-password')) {
        toast(`${t('TOAST_MESSAGES.PASSWORD_STRONG_ERROR')}`, 'error');
      } else if (error.code.includes('auth/email-already-in-use')) {
        toast(`${t('TOAST_MESSAGES.EMAIL_IN_USE_ERROR')}`, 'error');
      } else {
        toast(`${t('TOAST_MESSAGES.REGISTER_ERROR')}`, 'error');
      }
      setRegistering(false);
    }
  };

  return (
    <>
      <div className="container login-box">
        <div className={`row ${styles.mainWrap}`}>
          <form onSubmit={signUpWithEmailAndPassword}>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-md-6 col-9">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <FontAwesomeIcon size="lg" color="lightpink" icon={faUser} />
                  </span>
                  <input type="text" className={styles.margins} placeholder={t('SIGN_UP.FULL_NAME')} maxLength={100} onChange={(event) => setName(event?.target?.value)} />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-md-6 col-9">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <FontAwesomeIcon size="lg" color="lightpink" icon={faEnvelope} />
                  </span>
                  <input type="email" className={styles.margins} placeholder={t('SIGN_UP.EMAIL')} maxLength={200} onChange={(event) => setEmail(event?.target?.value)} />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-md-6 col-9">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <FontAwesomeIcon size="lg" color="lightpink" icon={faLock} />
                  </span>
                  <input type="password" className={styles.margins} placeholder={t('SIGN_UP.PASSWORD')} maxLength={100} onChange={(event) => setPassword(event?.target?.value)} />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-4 col-md-6 col-9">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <FontAwesomeIcon size="lg" color="lightpink" icon={faCheckCircle} />
                  </span>
                  <input type="password" className={styles.margins} placeholder={t('SIGN_UP.CONFIRM_PASSWORD')} maxLength={100} onChange={(event) => setConfirmPassword(event?.target?.value)} />
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-2 col-md-3 col-4 mt-5 p-0">
                <button className={styles.btn} type="submit">
                  {t('SIGN_UP.SIGN_UP')}
                  {registering ? <span className="spinner-border spinner-border-sm mx-3" role="status" /> : null}
                </button>
              </div>
            </div>
          </form>
          <div className="text-white d-flex justify-content-center mt-3">
            {t('SIGN_UP.ALREADY_HAVE_ACCOUNT')}&nbsp;
            <Link to={'/login'}>{t('SIGN_UP.LOGIN')}</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
