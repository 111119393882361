import React, { useState } from 'react';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fbBg from 'assets/images/fb_bg.png';
import gmBg from 'assets/images/gm_bg.png';
import gmLogo from 'assets/images/gm_logo.png';
import fbLogo from 'assets/images/fb_logo.png';
import styles from './Login.module.scss';
import { auth, Providers } from '../../firebase';
import { AuthProvider, signInWithEmailAndPassword, signInWithPopup, UserCredential } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { createUserSettings } from 'services/users';
import { setUserState } from 'state_management/localStates';
import { getUserDetails } from 'state_management/helpers';
import { reschedule } from 'services/messages';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [authenticating, setAuthenticating] = useState<boolean>(false);

  const isValidForm = () => {
    if (!email) {
      toast(`${t('TOAST_MESSAGES.EMAIL_ERROR')}`, 'error');
      return false;
    } else if (!password) {
      toast(`${t('TOAST_MESSAGES.PASSWORD_ERROR')}`, 'error');
      return false;
    }
    return true;
  };

  const signInWithEmailNPassword = async () => {
    if (!isValidForm()) {
      return;
    }
    setAuthenticating(true);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      // const userSettings: UserSettings | null = await getUserSettings(result.user?.uid);
      // if (userSettings?.twoFactorAuth) {
      //   navigate('/authenticate');
      //   return;
      // }
      setUserState(result);
      await getUserDetails();
      await rescheduleMessages();
      setAuthenticating(false);
      navigate('/');
    } catch (error: any) {
      console.error(`[ERROR] ${error}`);
      setAuthenticating(false);
      toast(`${t('TOAST_MESSAGES.CREDENTIALS_ERROR')}`, 'error');
    }
  };

  const SignInWithSocialMedia = (provider: AuthProvider) =>
    new Promise<UserCredential>((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });

  const signInWithSocialMedia = async (provider: AuthProvider) => {
    try {
      const response: any = await SignInWithSocialMedia(provider);
      // const userSettings: UserSettings | null = await getUserSettings(response.user?.uid);
      // if (userSettings?.twoFactorAuth) {
      //   navigate('/authenticate');
      //   return;
      // }
      setUserState(response);
      const isNewUser = response?._tokenResponse?.isNewUser;
      if (isNewUser) {
        await createUserSettings(response?.user?.uid);
      }
      await getUserDetails();
      await rescheduleMessages();
      navigate('/');
    } catch (error: any) {
      console.error(`[ERROR] ${error}`);
      toast(error?.message, 'error');
    }
  };

  const rescheduleMessages = async () => {
    try {
      await reschedule();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="container login-box">
        <div className={`row ${styles.mainWrap}`}>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9">
              <div className={styles.singleInput}>
                <span className={styles.margins}>
                  <FontAwesomeIcon size="lg" icon={faUser} />
                </span>
                <input type="text" className={styles.margins} placeholder={t('LOGIN.EMAIL')} onChange={(event) => setEmail(event?.target?.value)} />
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9">
              <div className={styles.singleInput}>
                <span className={styles.margins}>
                  <FontAwesomeIcon size="lg" icon={faLock} />
                </span>
                <input type="password" className={styles.margins} placeholder={t('LOGIN.PASSWORD')} onChange={(event) => setPassword(event?.target?.value)} />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mb-3">
            <div className="col-lg-2 col-md-4 col-4">
              <div className="form-check ps-0" hidden={true}>
                <input className="formCheckInput" type="checkbox" value="" id="flexCheckDefault" />
                <label className="form-check-label text-white fs-6" htmlFor="flexCheckDefault">
                  {t('LOGIN.REMEMBER_ME')}
                </label>
              </div>
            </div>
            <div className={`col-lg-2 col-md-3 col-5 ${styles.alignContent}`}>
              <Link to={'/forgot-password'} className="fst-italic text-white fs-6 positionAtEnd text-decoration-none">
                {t('LOGIN.FORGOT_PASSWORD')}
              </Link>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9">
              <div className={styles.singleInput}>
                <button className={styles.btn} onClick={signInWithEmailNPassword} disabled={authenticating}>
                  {t('LOGIN.LOGIN')}
                  {authenticating ? <span className="spinner-border spinner-border-sm mx-3" role="status" /> : null}
                </button>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9">
              <div className={styles.singleInput}>
                <button className={`${styles.btn}`} onClick={() => navigate('/signup')}>
                  {' '}
                  {t('LOGIN.SIGN_UP')}
                </button>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9 mb-2">
              <div className={styles.socialLogin}>
                <img alt="bgsocial" src={fbBg} className={styles.socialPosition} />
                <img alt="bgsocial" src={fbLogo} className={styles.socialPosition} />
                <button className={`${styles.socialBtn}`} onClick={() => signInWithSocialMedia(Providers.facebook)}>
                  {t('LOGIN.LOGIN_WITH_FACEBOOK')}
                </button>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9 mb-3">
              <div className={styles.socialLogin}>
                <img alt="bgsocial" src={gmBg} className={styles.socialPosition} />
                <img alt="bgsocial" src={gmLogo} className={styles.socialPosition} />
                <button className={`${styles.socialBtn}`} onClick={() => signInWithSocialMedia(Providers.google)}>
                  {t('LOGIN.LOGIN_WITH_GOOGLE')}
                </button>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4 col-md-7 col-9 mb-3 d-flex justify-content-around">
              <Link to={'/privacypolicy'} className="text-white text-decoration-none">
                {t('TERM_&_CONDITION.PRIVACY')}
              </Link>
              <Link to={'/termsofservice'} className="text-white text-decoration-none">
                {t('TERM_&_CONDITION.TERM')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
