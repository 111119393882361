import { firestore } from '../firebase';
import { collection, doc, query, where, getDocs, updateDoc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { UserSettings } from 'types';
import { DEFAULT_USER_SETTINGS } from 'state_management/initialStates';
import { getUserState } from 'state_management/localStates';

export const createUserSettings = async (userUID: string) => {
  try {
    const newUserSettings: UserSettings = {
      ...DEFAULT_USER_SETTINGS,
      _id: userUID,
      created_time: serverTimestamp(),
      updated_time: serverTimestamp()
    };
    return await setDoc(doc(collection(firestore, 'user_settings')), newUserSettings);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const updateUserSettings = async (userSettings: UserSettings) => {
  try {
    const currentUser = getUserState();
    const q = query(collection(firestore, 'user_settings'), where('_id', '==', currentUser?.user?.uid));
    const querySnapshot = await getDocs(q);
    const newUserSettings: UserSettings = {
      ...userSettings,
      updated_time: serverTimestamp()
    };
    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, newUserSettings);
    });
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getUserSettings = async (userUID?: string) => {
  try {
    const currentUser = getUserState();
    const q = query(collection(firestore, 'user_settings'), where('_id', '==', userUID || currentUser?.user?.uid));
    const querySnapshot = await getDocs(q);
    const userSettings: UserSettings[] = [];
    querySnapshot.forEach((doc) => {
      userSettings.push(doc.data() as UserSettings);
    });
    return userSettings && userSettings[0];
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getPaymentDetails = async () => {
  try {
    const currentUser = getUserState();
    const docRef = doc(firestore, 'stripe_customers', currentUser?.user?.uid);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : null;
  } catch (err) {
    console.error(err);
    return err;
  }
};
