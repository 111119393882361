import { firestore } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Notification } from 'types';
import { getUserState } from 'state_management/localStates';

export const getUserNotifications = async () => {
  try {
    const currentUser = getUserState();
    const q = query(collection(firestore, 'notifications'), where('user_id', '==', currentUser?.user?.uid));
    const querySnapshot = await getDocs(q);
    const notifications: Notification[] = [];
    querySnapshot.forEach((doc) => {
      notifications.push(doc.data() as Notification);
    });
    return notifications;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
