import React, { useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import passwordicon from 'assets/icons/passwordicon.png';
import payementicon from 'assets/icons/payementicon.png';
import languageicon from 'assets/icons/languageicon.png';
import howtouseicon from 'assets/icons/howtouseicon.png';
import moveblockicon from 'assets/icons/moveblockicon.png';
import logouticon from 'assets/icons/logouticon.png';
import repeatreminder from 'assets/icons/repeatReminder.png';
import twofactoricon from 'assets/icons/twofactorIcon.png';
import InputGroup from 'react-bootstrap/InputGroup';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { openLink, toast } from 'helpers/utils';
import { UserSettings } from 'types';
import { DEFAULT_USER_SETTINGS } from 'state_management/initialStates';
import { getPaymentsState, getUserSettingsState, setUserSettingsState } from 'state_management/localStates';
import { updateUserSettings } from 'services/users';
import { LANGUAGES } from 'constants/index';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { createBillingPortalSession } from 'services/payments';
import config from 'config.json';

function Settings() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const languages = LANGUAGES;
  const [userSettings, setUserSettings] = useState<UserSettings>(DEFAULT_USER_SETTINGS);

  useEffect(() => {
    const settings = getUserSettingsState();
    setUserSettings(settings);
  }, []);

  const updateSettings = async (settings: UserSettings) => {
    try {
      await updateUserSettings(settings);
      setUserSettings(settings);
      setUserSettingsState(settings);
    } catch (err: any) {
      toast(err?.message || `${t('TOAST_MESSAGES.UPDATE_ERROR')}`, 'error');
    }
  };

  const updateLanguage = async (language: string) => {
    const newUserSettings = {
      ...userSettings,
      language: language
    };
    await updateSettings(newUserSettings);
  };

  const updateBlockDays = async (days: number) => {
    const newUserSettings = {
      ...userSettings,
      block: {
        ...userSettings?.block,
        days
      }
    };
    await updateSettings(newUserSettings);
  };

  const updateBlockMonths = async (months: number) => {
    const newUserSettings = {
      ...userSettings,
      block: {
        ...userSettings?.block,
        months
      }
    };
    await updateSettings(newUserSettings);
  };

  const updateBlockYears = async (years: number) => {
    const newUserSettings = {
      ...userSettings,
      block: {
        ...userSettings?.block,
        years
      }
    };
    await updateSettings(newUserSettings);
  };

  const updateReminderDays = async (days: number) => {
    const newUserSettings = {
      ...userSettings,
      reminder: {
        ...userSettings?.reminder,
        days
      }
    };
    await updateSettings(newUserSettings);
  };

  const updateReminderMonths = async (months: number) => {
    const newUserSettings = {
      ...userSettings,
      reminder: {
        ...userSettings?.reminder,
        months
      }
    };
    await updateSettings(newUserSettings);
  };

  const updateReminderYears = async (years: number) => {
    const newUserSettings = {
      ...userSettings,
      reminder: {
        ...userSettings?.reminder,
        years
      }
    };
    await updateSettings(newUserSettings);
  };

  const updateTwoFactorAuth = async (event: any) => {
    const newUserSettings = {
      ...userSettings,
      twoFactorAuth: event?.target?.checked
    };
    await updateSettings(newUserSettings);
  };

  const logout = async () => {
    try {
      const result = await signOut(auth);
      navigate('/logout');
      setTimeout(() => {
        localStorage.clear();
        navigate('/login');
      }, 2000);
      console.log(`[INFO] ${result}`);
    } catch (error) {
      console.log(`[ERROR] ${error}`);
    }
  };

  const openBillingPortal = async () => {
    try {
      const paymentsState = getPaymentsState();
      const body = {
        stripeCustomerId: paymentsState?.customer_id,
        returnUrl: window.location.href
      };
      const url = await createBillingPortalSession(body);
      if (url) {
        openLink(url, '_self');
      }
    } catch (err) {
      console.log('[openBillingPortal] ', err);
      toast(t('TOAST_MESSAGES.FAILED_TO_CREATE_CUSTOMER_PORTAL_SESSION'), 'error');
    }
  };

  return (
    <>
      <div className="inner-container create-message-inner-container">
        <div className="row d-flex justify-content-center">
          <div className=" d-flex justify-content-center mb-4">
            <h1 className="text-center page-heading">{t('SETTINGS.HEADING')}</h1>
          </div>
        </div>
        <div className="container login-box">
          <div className={`row ${styles.settingbox}`}>
            <div className={`align-items-center width-100 ${styles.settinginnerboxs}`} onClick={() => navigate('/change-password')}>
              <div className="">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <img src={passwordicon} className={styles.iconSize} alt="iconlogo" />
                  </span>
                  <div className={styles.customlables}>{t('SETTINGS.PASSWORD')}</div>
                </div>
              </div>
            </div>

            <div className={`align-items-center width-100 ${styles.settinginnerboxs} `}>
              <div className="">
                <div className={`justify-content-between align-items-center ${styles.singleInput} `}>
                  <div>
                    <span className={styles.margins}>
                      <img src={twofactoricon} className={styles.iconSize} alt="iconlogo" vertical-align="bottom" />
                    </span>
                    <label className={styles.customlables}>{t('SETTINGS.TWO_FACTOR_AUTHENTICATION')}</label>
                  </div>
                  <div className={`form-check form-switch p-0 ${styles.checkForm}`}>
                    <input className={`form-check-input m-0 ${styles.formcheckinput}`} type="checkbox" id="flexSwitchCheckChecked" checked={!!userSettings?.twoFactorAuth} onChange={updateTwoFactorAuth} />
                  </div>
                </div>
              </div>
            </div>

            {config?.showPayments ? (
              <div className={`align-items-center width-100 ${styles.settinginnerboxs}`} onClick={openBillingPortal}>
                <div className="">
                  <div className={styles.singleInput}>
                    <span className={styles.margins}>
                      <img src={payementicon} className={styles.iconPaymentSize} alt="iconlogo" />
                    </span>
                    <div className={styles.customlables}>{t('SETTINGS.PAYMENT')}</div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className={`align-items-center width-100 ${styles.settinginnerboxs} `}>
              <div className="">
                <div className={`justify-content-between align-items-center ${styles.singleInput} `}>
                  <div>
                    <span className={styles.margins}>
                      <img src={languageicon} className={styles.iconPaymentSize} alt="iconlogo" />
                    </span>
                    <label className={styles.customlables}>{t('SETTINGS.LANGUAGE')}</label>{' '}
                  </div>
                  {[''].map((variant) => (
                    <SplitButton key={variant} className={styles.dropDownColor} id={`dropdown-split-variants-${variant}`} variant={variant.toLowerCase()} title={variant}>
                      {languages?.map((language) => {
                        return (
                          <Dropdown.Item key={language?.label} eventKey={language?.key} onClick={() => updateLanguage(language?.key)}>
                            {language?.label}
                          </Dropdown.Item>
                        );
                      })}
                    </SplitButton>
                  ))}
                </div>
              </div>
            </div>

            <div className={`align-items-center width-100 ${styles.settinginnerboxs}`} onClick={() => navigate('/how-to-use')}>
              <div className="">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <img src={howtouseicon} className={styles.iconPaymentSize} alt="iconlogo" />
                  </span>
                  <div className={styles.customlables}>{t('SETTINGS.HOW_TO_USE')}</div>
                </div>
              </div>
            </div>

            <div className={`align-items-center width-100 ${styles.settinginnerboxs} `}>
              <div className="">
                <InputGroup className="custom-input-drop justify-content-between">
                  <div className={styles.singleInput}>
                    <span className={styles.margins}>
                      <img src={moveblockicon} className={styles.iconPaymentSize} alt="iconlogo" />
                    </span>
                    <div className={styles.customlables}>{t('SETTINGS.DEFAULT_BLOCK')}</div>
                  </div>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle>{t('SETTINGS.DAY')}</Dropdown.Toggle>
                      <Dropdown.Menu className="dropdownMenu">
                        {[...Array(31)].map((e, i) => (
                          <Dropdown.Item key={i + 1} className={userSettings?.block?.days === i + 1 ? styles.selectedItem : ''} onClick={() => updateBlockDays(i + 1)}>
                            {i + 1}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle>{t('SETTINGS.MONTH')}</Dropdown.Toggle>
                      <Dropdown.Menu className="dropdownMenu">
                        {[...Array(12)].map((e, i) => (
                          <Dropdown.Item key={i + 1} className={userSettings?.block?.months === i + 1 ? styles.selectedItem : ''} onClick={() => updateBlockMonths(i + 1)}>
                            {i + 1}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle>{t('SETTINGS.YEAR')}</Dropdown.Toggle>
                      <Dropdown.Menu className="dropdownMenu">
                        {[...Array(10)].map((e, i) => (
                          <Dropdown.Item key={i + 1} className={userSettings?.block?.years === i + 1 ? styles.selectedItem : ''} onClick={() => updateBlockYears(i + 1)}>
                            {i + 1}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </InputGroup>
              </div>
            </div>
            <div className={`align-items-center width-100 ${styles.settinginnerboxs} `}>
              <div className="">
                <InputGroup className="custom-input-drop justify-content-between">
                  <div className={styles.singleInput}>
                    <span className={styles.margins}>
                      <img src={repeatreminder} className={styles.iconPaymentSize} alt="iconlogo" />
                    </span>
                    <div className={styles.customlables}>{t('SETTINGS.REMINDER_FREQUENCY')}</div>
                  </div>
                  <div>
                    <Dropdown>
                      <Dropdown.Toggle>{t('SETTINGS.DAY')}</Dropdown.Toggle>
                      <Dropdown.Menu className="dropdownMenu">
                        {[...Array(31)].map((e, i) => (
                          <Dropdown.Item key={i + 1} className={userSettings?.reminder?.days === i + 1 ? styles.selectedItem : ''} onClick={() => updateReminderDays(i + 1)}>
                            {i + 1}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle>{t('SETTINGS.MONTH')}</Dropdown.Toggle>
                      <Dropdown.Menu className="dropdownMenu">
                        {[...Array(12)].map((e, i) => (
                          <Dropdown.Item key={i + 1} className={userSettings?.reminder?.months === i + 1 ? styles.selectedItem : ''} onClick={() => updateReminderMonths(i + 1)}>
                            {i + 1}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                      <Dropdown.Toggle>{t('SETTINGS.YEAR')}</Dropdown.Toggle>
                      <Dropdown.Menu className="dropdownMenu">
                        {[...Array(10)].map((e, i) => (
                          <Dropdown.Item key={i + 1} className={userSettings?.reminder?.years === i + 1 ? styles.selectedItem : ''} onClick={() => updateReminderYears(i + 1)}>
                            {i + 1}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </InputGroup>
              </div>
            </div>
            <div className={`align-items-center width-100 ${styles.settinginnerboxs}`} onClick={logout}>
              <div className="">
                <div className={styles.singleInput}>
                  <span className={styles.margins}>
                    <img src={logouticon} className={styles.iconPaymentSize} alt="iconlogo" />
                  </span>
                  <div className={styles.customlables}>{t('SETTINGS.LOGOUT')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
